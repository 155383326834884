.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.my-style {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.my-style a {
  width: calc(50% - 10px);
  font-size: small;
}
.card-body {
  display: inline-grid;
}
.hidden {
  display: none !important;
}
.child_ {
  overflow: auto;
}
@media (max-width: 398px) {
  .my-style a {
    font-size: 0.76rem;
  }
  .my-style a svg {
    display: none;
  }
}
.project-card {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
